import { useState, useEffect } from 'react';

/**
 * Example app data imports 
 */
import { fileUploader, loadAssets, deleteAsset, updateLibraryAsset } from './AppData';

/**
 * Example app imports
 */
import { pageOne, pageTwo, pageThree, pageFour } from "./ExistingData.js";
import { messageValidations } from "./Validations.js";
import { CustomHeader } from './CustomHeader.js';

/**
 * cb-message-builder imports
 */
import 'cb-message-builder/dist/index.css'
import { CBMessageBuilder, CBPage, CBContentPreviewsModal, UniversityComponent, CBMessagePreview, CBNotification, CBContentBuilderModal } from 'cb-message-builder';
import { components, componentTemplates } from 'cb-message-builder';
import { BlankMessageTemplate, ImageMessageTemplate, CollegeMessageTemplate, QuizMessageTemplate } from 'cb-message-builder';

const App = () => {
    // Pages and asset data
    const [pages, setPages] = useState([])
    const [assets, setAssets] = useState({})

    // State for the demo
    const [showPreview, setShowPreview] = useState(false)
    const [pageVersion, setPageVersion] = useState(0)
    const [showNotification, setShowNotification] = useState(false)

    const [showEditableTemplates, setShowEditableTemplates] = useState(false)
    const [options, setOptions] = useState({ cropping: true, multiUpload: true, assetFolders: false, debug: false, editActionTitle: false })
    
    // Options when creating a new page
    const [newPageOptions, setNewPageOptions] = useState({})

    // Existing Page Data
    const existingPages = [pageOne, pageTwo, pageThree, pageFour]
    /**
     * ----------------------------------------------------------------
     * Component Configuration
     */

    // Define info message for the university component to instruct a user
    // where that content can be edited
    UniversityComponent.infoMessage = (<span>
        University profile information can be updated in the <a href="">Profile Screen</a>
    </span>)

    // Setup some institution data
    let institutionData =  {
        name: "Big Future",
        location: "College Board",
        iconURL: "https://firebasestorage.googleapis.com/v0/b/cb-prototype-4e3e7.appspot.com/o/branding-assets%2Fgenericuniversity-BigFuture%20-%20Square%20Logo.png?alt=media&token=a2f5a5ce-7f07-43b9-9294-599c19f2d714"
    }

    /**
     * ----------------------------------------------------------------
     * Template Configuration
     * for the default college message
     */
    const templateLibrary = [
        {
            name: 'Messages',
            templates: [
                { template: new BlankMessageTemplate({ institution: institutionData }), title: "Blank Message" },
                { template: new ImageMessageTemplate({ institution: institutionData }), title: "Image" },
                { template: new QuizMessageTemplate({ institution: institutionData }), title: "Quiz" },
            ]
        },
    ]

    /*
     * Action button title customisations
     */
    //
    // If need to customise button titles when not editable.
    //
    // These are defined as static class variables so any changes to 
    // these labels applies to all existing messages.
    //
    // LearnMoreButtonComponent.title = "Learn!"
    // RequestInformationButtonComponent.title = "Connect"

    const defaultTemplate = new CollegeMessageTemplate({
        
        // Define the action button, possible options are:
        // - learnMore          - [deeplink] - Deeplink to institution page. Default title 'Explore More', Title & URL cannot be edited by default. 
        // - requestInformation - [external] - External browser web link. Title 'Connect'. Title & URL cannot be edited by default. 
        // - custom             - [internal] - Internal college board action. Title 'Action Button'. Both fields can be edited by user by default.
        // - null               - Dont show an action

        action: 'requestInformation', 

        // Default action button url
        actionURL: "https://collegeboard.org",    

        // Properties to change default editing behaviour of action buttons
        actionTitle: "Apply", // Used as default when title is editable, otherwise title comes from static vars
        actionCanEditTitle: newPageOptions.editableActionTitle == true ? true : false,
        
        title: "Add Message Title",
        body: "Add Your Message",
        headerImage: {
            placeholderMessage: "Add Header Image"
        },

        date: new Date(),
        image: {
            footnote: "Add Image Description",
            placeholderMessage: "Add Message Image"
        },
        institution: {
            name: "University of Virginia",
            location: "Virginia",
            iconURL: "https://firebasestorage.googleapis.com/v0/b/cb-prototype-4e3e7.appspot.com/o/UVA%20-%20Logo%20-%20Square%20-%20Large.png?alt=media&token=8eed43e1-e049-4827-aff9-36de97543ee7"
        }
    })


    /**
     * ----------------------------------------------------------------
     * Validation Configuration
     */

    const validations = defaultTemplate.applyValidation(messageValidations)


    /**
     * ----------------------------------------------------------------
     * Callback Configuration
     */

    // onAssetImport callback
    //
    // Passes in the follow parameters 
    // - file: HTML File object that represents the file selected by the user
    // - asset: Suggested dictionary for convenience to represent the asset object. Will contain id, type, title, dimensions.
    // - album: Album the user is currently in 
    // - progressCallback: Callback to call when upload progress changes. progressCallback(percentComplete);
    // - completeCallback: Callback to call when upload is complete. completeCallback(error)
    // - errorCallback: Callback to call if theres an error errorCallback(message)
    //
    // Return value:
    //   Function to call that will be called if the upload is cancelled by the user
    //
    const onAssetImport = (file, asset, album, progressCallback, completeCallback, errorCallback) => {
        console.log('onAssetImport')
        console.log(asset)

        // Create a new asset
        var asset = asset
        asset.album = album.name

        const uploadTask = fileUploader({
            institutionId: "test",
            file: file,
            asset: asset,
            progressCallback: progressCallback,
            completeCallback: completeCallback,
            errorCallback: errorCallback,
            setAssets: setAssets
        })

        // Return value to onAssetImport is a function that will be called if the upload is cancelled by the user
        return () => {
            uploadTask.cancel()

            // Show cancelled message here if required
            errorCallback('User Cancelled!')
        }
    }

    // onAssetDeleteCallback callback
    // Called when an asset is deleted
    //
    // Passes the following parameters
    // - asset: Asset dictionary represeting the object
    const onAssetDelete = (asset) => {
        console.log('onAssetDelete')
        console.log(asset)
        setAssets({ ...deleteAsset(asset, "test") })
    }

    // onAssetChangeCallback callback
    // Called when an asset's properties are updated by the user.
    //
    // Passes the following parameters
    // - asset: Asset dictionary represeting the object
    const onAssetChange = (asset) => {
        console.log('onAssetChange')
        console.log(asset)
        setAssets({ ...updateLibraryAsset(asset, "test") })
    }

    // onPagesUpdated callback
    // Called when an the user makes changes to message content.
    const onPagesUpdated = (pages) => {
        setPages(pages)
        console.log(`onPagesUpdated length [${JSON.stringify(pages).length}]`)
    }

    useEffect(() => {
        if (pages[0]) {
            onPagesUpdated([...pages])
        }
    }, [options.editActionTitle])

    // Handle done event
    // Called when user taps done button and passes validation
    const onDone = (validationPassed) => {
        console.log(`onDone validationPassed [${validationPassed}]`)
        if (validationPassed) {
            setShowNotification(true)
        }
    }

    /**
     * ----------------------------------------------------------------* 
     * Asset Loading (from Firebase)
     */
    useEffect(() => {
        // Load assets for the institution with the id 'test'
        loadAssets('test').then(
            (value) => {
                setAssets(value)
            },
            (error) => {
                console.log(error)
            }
        )
    }, [])

    let assetArray = Object.values(assets)

    /**
     * ----------------------------------------------------------------
     * React Configuration
     */

    return (
        <CBPage key={pageVersion} containerClassName={"container"}>
            <CBNotification title="Saved !" icon={"success"} show={showNotification} setShow={setShowNotification}/>
            <CustomHeader 
                title={"Message Builder"}
                setOptions={setOptions}
                options={options}
                loadPageAction={(index) => {
                    setPages([existingPages[index]])
                    setPageVersion(pageVersion + 1)
                }}
                newPageAction={(options) => {
                    setNewPageOptions(options ?? {})
                    setPages([])
                    setShowEditableTemplates(false)
                    setPageVersion(pageVersion + 1)
                }}
                newEditablePageAction={() => {
                    setPages([])
                    setShowEditableTemplates(true)
                    setPageVersion(pageVersion + 1)
                }}
                previewAction={() => {
                    setShowPreview(true)
                }} />

            <CBMessageBuilder
                /** Page data */
                pages={pages}

                /** Asset Library */
                assets={options.assetFolders == true ? [{ name: "Images" , assets: assetArray }] : assetArray}

                /** Templates that can be used when creating a new doc  */
                templates={showEditableTemplates ? templateLibrary : null}

                /** List of all available components */
                components={components}

                /** Components that can be selected when a template is editable */
                componentTemplates={componentTemplates}

                /** Validation data */
                validations={showEditableTemplates ? null : validations}

                /** Default template to use if no 'templates' passed for selection */
                defaultTemplate={defaultTemplate}

                /** Callbacks */
                onPagesUpdated={onPagesUpdated}
                onAssetImport={onAssetImport}
                onAssetDelete={onAssetDelete}
                onAssetChange={onAssetChange}
                onDone={onDone}

                /** Disable / enable cropping */
                canCrop={options.cropping}

                /** Disable / enable multi upload */
                canMultiUpload={options.multiUpload}

                /** Shows debug view */
                showDebug={options.debug}

            />

            <CBContentPreviewsModal show={showPreview} onClose={setShowPreview} >
                <CBMessagePreview name="Content Preview" page={pages ? pages[0] : null} />
            </CBContentPreviewsModal>
            
        </CBPage>
    )
}

export default App
