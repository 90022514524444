import React from 'react'

import { MessageHeaderComponent } from './Components/MessageHeaderComponent.js';
import { UniversityComponent } from './Components/UniversityComponent.js';
import { TextComponent } from './Components/TextComponent.js';
import { ImageComponent } from './Components/ImageComponent.js';
import { CalendarComponent } from './Components/CalendarComponent.js';
import { GalleryComponent } from './Components/GalleryComponent.js';
import { QuizComponent } from './Components/QuizComponent.js';
import { VideoComponent } from './Components/VideoComponent.js';
import { NumberComponent } from './Components/NumberComponent.js';
import { ActionButtonComponent, RequestInformationButtonComponent, LearnMoreButtonComponent } from './Components/ActionButtonComponent.js';

/**
 * These are all available components. For use within templates and for users to drag and drop.
 */
const components = [
    new MessageHeaderComponent(),
    new TextComponent(),
    new ImageComponent(),
    new QuizComponent(),
    new VideoComponent(),
    new NumberComponent(),
    new CalendarComponent(),
    new ActionButtonComponent(),
    new UniversityComponent(),
    new GalleryComponent(),
    new LearnMoreButtonComponent(),
    new RequestInformationButtonComponent()
]

/**
 * These are components available for users to drag and drop.
 */
const componentTemplates = [
    {
        title: 'Content',
        columns: 2,
        components: [
            { template: new TextComponent("Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam"), title: "Paragraph" },
            { template: new TextComponent("Section Header", 'h2'), title: "Section Header" },
            { template: new NumberComponent(), title: "Number" },
        ]
    },

    {
        title: 'Media',
        columns: 2,
        icon: 'gallery',
        components: [
            { template: new ImageComponent(), title: "Image" },
            { template: new GalleryComponent(), title: "Gallery" },
            { template: new VideoComponent(), title: "Video" }
        ]
    },

    {
        title: 'Interactive',
        icon: 'quiz',
        columns: 2,
        components: [
            { template: new QuizComponent(),  title: "Quiz Question" },
            { template: new ActionButtonComponent(), title: "Action Button"},
            { template: new CalendarComponent(), title: "Calendar Event" },
        ]
    },

    {
        title: 'Debug',
        icon: 'debug',
        columns: 2,
        components: [
            { template: new ActionButtonComponent(),  title: "Internal Action" },
            { template: new RequestInformationButtonComponent("https://somedefaulturl.com"),  title: "College Action" },
            { template: new RequestInformationButtonComponent("https://somedefaulturl.com", { title: "Apply", canEditTitle: true }),  title: "College Action (Editable)" },
            { template: new LearnMoreButtonComponent(), title: "Deep Link"},
        ]
    },

]

export { components, componentTemplates,
         MessageHeaderComponent, 
         TextComponent, 
         ImageComponent, 
         QuizComponent, 
         NumberComponent,
         CalendarComponent, 
         VideoComponent, 
         ActionButtonComponent, 
         RequestInformationButtonComponent,  
         LearnMoreButtonComponent, 
         UniversityComponent }
