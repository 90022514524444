import React from 'react'
import { YapContentBuilder, YapContentPreview, YapAssetCatalogue, YapPage, YapHeader, YapNotification as CBNotification, YapHeaderButton as CBHeaderButton, YapContentValidations, YapAssetImage as CBAssetImage, YapContentPreviewsModal as CBContentPreviewsModal, useYapPageState as useCBPageState, YapContentBuilderIconProviderContext as CBMessageBuilderIconProviderContext } from '@yapstudios/yap-content-builder'
import '@yapstudios/yap-content-builder/dist/index.css'
import { templateLibrary, CollegeMessageTemplate, BlankMessageTemplate, ImageMessageTemplate, QuizMessageTemplate } from './CollegeboardTemplateLibrary'
import { components, componentTemplates, MessageHeaderComponent, TextComponent, LearnMoreButtonComponent, ActionButtonComponent, RequestInformationButtonComponent, UniversityComponent } from './CollegeboardComponentLibrary'
import { allPreviews, CollegeboardAssetPreviewLibrary } from './CollegeboardAssetPreviewLibrary.js';
import { assetTemplates as defaultAssetTemplates } from './CollegeboardAssetTemplateLibrary.js';
import CBMessageFeedPreview from './CBMessageFeedPreview.js'
import CBInstitutionPreview from './CBInstitutionPreview.js'
import CBMessagePreview from './CBMessagePreview.js'
import { CBSavedListPreview, CBCollegeListPreview } from './CBCollegeListPreview.js'
import { convertToPage, convertFromPage } from "./MessageConvert.js"
import { avatarPreviews, wordmarkPreviewsLight, wordmarkPreviewsDark, headerPreview } from "./CollegeboardAssetPreviewLibrary.js"
import CBIconProvider from "./CBIconProvider.js"
import { styles, fonts } from "./CBStyles.js"
import './styles.css'
import packageJson from '../package.json';

const packageInformation = {
    name: packageJson.name,
    version: packageJson.version,
    yapContentBuilder: {
        version: packageJson.dependencies['@yapstudios/yap-content-builder'],
        name: '@yapstudios/yap-content-builder'
    }
}

/**
 * Presents an interface to allow messages to be created and edited 
 * @param {pages} Pages data
 * @param {onPagesUpdated} Callback when page data is updated
 * @returns {React.ReactElement} The view
 */
const CBMessageBuilder = (props) => {
    const [pages, setPages] = React.useState(null)

    const defaultTemplate = props.defaultTemplate ?? new CollegeMessageTemplate()

    const onPagesUpdated = (newPages) => {
        if (props.onPagesUpdated) {
            props.onPagesUpdated(newPages)
        }
        setPages(newPages)
    }


    return (
        <CBIconProvider>
            <YapContentBuilder components={components}
                componentTemplates={null}
                onPagesUpdated={onPagesUpdated}
                defaultTemplate={defaultTemplate}
                templates={null}
                onAssetImport={props.onAssetImport}
                styles={styles}
                fonts={fonts}
                {...props}
                pages={props.pages ?? pages}
            />
        </CBIconProvider>
    )
}

/**
 * Asset Catalogue
 * 
 * @param   {dictionary} assets <description>
 * @param   {function} onAssetSelected <description>
 * @param   {function} onFileSelected <description>
 * @returns {React.ReactElement} The view
 */
const CBAssetCatalog = ({ assets, assetTemplates, previewAssetPath, previewCallback, canCrop, canMultiUpload, onAssetSelected, onAssetDelete, onAssetChange, onFileSelected, assetLibrary, onAssetLibraryDelete, onAssetLibraryImport, onAssetLibraryChange }) => {

    if (assets == null) {
        throw Error('CBAssetCatalog requires assets property')
    }

    CollegeboardAssetPreviewLibrary._previewCallback = previewCallback

    return (
        <CBIconProvider>
            <YapAssetCatalogue previews={allPreviews}
                assets={assets}
                assetTemplates={assetTemplates ?? defaultAssetTemplates}

                onAssetSelected={onAssetSelected}
                onAssetChange={onAssetChange}
                onAssetDelete={onAssetDelete}

                onFileSelected={onFileSelected}

                previewAssetPath={previewAssetPath}

                assetLibrary={assetLibrary}
                onAssetLibraryDelete={onAssetLibraryDelete}
                onAssetLibraryImport={onAssetLibraryImport}
                onAssetLibraryChange={onAssetLibraryChange}

                canCrop={canCrop}
                canMultiUpload={canMultiUpload}
            />
        </CBIconProvider>
    )
}

/**
 * Page component that manages page contnet for CBMessageBuilder & CBAssetCatalog content. 
 * Currently is just a name wrapper around YapPage but is a placeholder here to have more specific CB customisation in the future.
 * @param {*} props 
 * @returns {React.ReactElement} The view
 */
const CBPage = (props) => {
    return <YapPage defaultContainerClassName={""} {...props} />
}

/**
 * Header component that renders a header for CBMessageBuilder & CBAssetCatalog content. 
 * Currently is just a name wrapper around YapHeader but is a placeholder here to have more specific CB customisation in the future.
 * @param {*} props 
 * @returns {React.ReactElement} The view
 */
const CBHeader = (props) => {
    return <YapHeader {...props} />
}

/**
 * Message Validations
 */
const CBMessageValidations = {}

/**
 * Field required validation
 * CBContentValidations.fieldRequired('fieldName', { component: new TheComponentToValidation() })
 */
CBMessageValidations.fieldRequired = (field, props) => {
    return YapContentValidations.fieldRequired(field, props ?? {})
}

/**
 * Min Length validation
 * CBContentValidations.minimumLengthWarning('fieldName', { length: 100, component: new TheComponentToValidation() })
 */
CBMessageValidations.minimumLengthWarning = YapContentValidations.minimumLengthWarning
CBMessageValidations.minimumLengthError = YapContentValidations.minimumLengthError

/**
 * Max Length validation
 * CBContentValidations.maximumLengthWarning('fieldName', { length: 100,component: new TheComponentToValidation() })
 */
CBMessageValidations.maximumLengthWarning = YapContentValidations.maximumLengthWarning
CBMessageValidations.maximumLengthError = YapContentValidations.maximumLengthError

/**
 * Image Validations
 */
CBMessageValidations.imageDimensionsRequired = YapContentValidations.imageDimensionsRequired
CBMessageValidations.imageDimensionsWarning = YapContentValidations.imageDimensionsWarning
CBMessageValidations.image = YapContentValidations.image

/**
 * Prevent Links validation
 * CBContentValidations.preventLinks('fieldName', { component: new TheComponentToValidation() })
 */
CBMessageValidations.preventLinks = YapContentValidations.preventLinks
CBMessageValidations.requireHTTPSLinks = YapContentValidations.requireHTTPSLinks
CBMessageValidations.urlProtocolsRequired = YapContentValidations.urlProtocolsRequired

//import { allPreviews, CollegeboardAssetPreviewLibrary } from './CollegeboardAssetPreviewLibrary.js';

var CBMessagePreviewLibrary = { avatarPreviews, wordmarkPreviewsLight, wordmarkPreviewsDark, headerPreview }

export {
    CBMessageBuilder,
    CBMessagePreview,
    CBMessageFeedPreview,
    CBCollegeListPreview,
    CBInstitutionPreview,
    CBSavedListPreview,
    CBMessageBuilderIconProviderContext,
    CBAssetCatalog,
    CBPage,
    CBHeader,
    CBHeaderButton,
    CBNotification,
    CBAssetImage,
    CBContentPreviewsModal,
    CBIconProvider,
    CBMessagePreviewLibrary,
    useCBPageState,
    templateLibrary,
    components,
    componentTemplates,
    MessageHeaderComponent, 
    TextComponent, 
    LearnMoreButtonComponent, 
    ActionButtonComponent, 
    RequestInformationButtonComponent, 
    UniversityComponent, 

    BlankMessageTemplate,
    ImageMessageTemplate,
    CollegeMessageTemplate,
    QuizMessageTemplate,

    CBMessageValidations, 
    convertFromPage, convertToPage,

    packageInformation
}
