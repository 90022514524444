import React from 'react';
import { Section, ActionButton } from '@yapstudios/yap-content-builder';

export class ActionButtonComponent {
    constructor(url, defaultOptions) {
        this.type = 'action'
        this.defaultURL = url
        this.defaultOptions = defaultOptions ?? {}
    }


    defaultProps() {
        let options = this.defaultOptions
        let defaults = {
            url: this.defaultURL,
            title: options.title ?? "Action",
        }
        if (options.canEditTitle == false) {
            defaults.canEditTitle = false
        }
        if (options.canEditURL == false) {
            defaults.canEditURL = false
        }
        return defaults
    }

    decode(props) {
        return (
            <Section padding={20} paddingVertical={20} paddingHorizontal={20} id="action-button" title="Action Button">
                    <ActionButton title={props.title} url={props.url} canEditText={props.canEditTitle != null ? props.canEditTitle : true} canEditURL={props.canEditURL != null ? props.canEditURL : true} />
            </Section>
        )
    }

    encode(model, props) {
        props['title'] = model.title
        props['url'] = model.url
        return props
    }

    icon() {
        return (
            <svg width="100" height="70" viewBox="0 0 100 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_25_1617)">
                    <path d="M100 0H0V70H100V0Z" fill="white" />
                    <path d="M89.6763 6.97676H10.4463C7.68487 6.97676 5.44629 9.21534 5.44629 11.9768V59.1308C5.44629 61.8922 7.68487 64.1308 10.4463 64.1308H89.6763C92.4377 64.1308 94.6763 61.8922 94.6763 59.1308V11.9768C94.6763 9.21534 92.4377 6.97676 89.6763 6.97676Z" fill="#EDEFFA" />
                    <path opacity="0.5" d="M28.457 40L31.6372 30.8403H33.8906L37.0771 40H35.0586L34.3794 37.7783H31.1484L30.4692 40H28.457ZM32.71 32.6685L31.5864 36.3438H33.9414L32.8242 32.6685H32.71ZM40.906 40.146C38.7224 40.146 37.4846 38.8193 37.4846 36.4961V36.4834C37.4846 34.1855 38.7161 32.8589 40.906 32.8589C42.7595 32.8589 43.8577 33.8682 44.0227 35.4297V35.4487H42.2961L42.2898 35.436C42.1692 34.7568 41.7058 34.3125 40.9123 34.3125C39.9158 34.3125 39.3699 35.0869 39.3699 36.4834V36.4961C39.3699 37.9116 39.9221 38.6924 40.9123 38.6924C41.6741 38.6924 42.1247 38.3306 42.2898 37.6323L42.3025 37.6069H44.0163L44.01 37.645C43.8196 39.1685 42.7278 40.146 40.906 40.146ZM48.1943 40.0254C46.4931 40.0254 45.8076 39.4731 45.8076 38.1147V34.4077H44.7285V33.0112H45.8076V31.3418H47.6802V33.0112H49.1211V34.4077H47.6802V37.6895C47.6802 38.3179 47.9468 38.5972 48.5752 38.5972C48.8164 38.5972 48.9433 38.5845 49.1211 38.5654V39.9429C48.8926 39.9873 48.5498 40.0254 48.1943 40.0254ZM51.3694 31.9385C50.7981 31.9385 50.322 31.4814 50.322 30.9102C50.322 30.3325 50.7981 29.8755 51.3694 29.8755C51.9406 29.8755 52.4104 30.3325 52.4104 30.9102C52.4104 31.4814 51.9406 31.9385 51.3694 31.9385ZM50.4363 40V33.0112H52.2898V40H50.4363ZM57.02 40.146C54.8681 40.146 53.5732 38.7812 53.5732 36.5088V36.4961C53.5732 34.2427 54.8872 32.8589 57.02 32.8589C59.1528 32.8589 60.4668 34.2363 60.4668 36.4961V36.5088C60.4668 38.7876 59.1718 40.146 57.02 40.146ZM57.02 38.6924C58.0039 38.6924 58.5688 37.8862 58.5688 36.5088V36.4961C58.5688 35.1313 57.9975 34.3125 57.02 34.3125C56.0361 34.3125 55.4712 35.1313 55.4712 36.4961V36.5088C55.4712 37.8862 56.0297 38.6924 57.02 38.6924ZM61.7375 40V33.0112H63.591V34.0903H63.7053C64.0227 33.335 64.7146 32.8589 65.7111 32.8589C67.2663 32.8589 68.1042 33.811 68.1042 35.4805V40H66.2507V35.8994C66.2507 34.9155 65.8444 34.395 64.9685 34.395C64.1115 34.395 63.591 35.0107 63.591 35.9565V40H61.7375Z" fill="#A3ABD5" />
                </g>
                <defs>
                    <clipPath id="clip0_25_1617">
                        <rect width="100" height="70" fill="white" />
                    </clipPath>
                </defs>
            </svg>

        )
    }

}


export class RequestInformationButtonComponent {
    static placeholderURL = null
    static title = "Connect"

    constructor(url, defaultOptions) {
        this.type = 'actionRequestInformation'
        this.defaultURL = url
        this.defaultOptions = defaultOptions ?? {}
    }

    defaultProps() {
        let options = this.defaultOptions

        var defaults = {
            url: this.defaultURL
        }

        if (options.canEditTitle == true) {
            defaults.title = (options.title ?? RequestInformationButtonComponent.title)
            defaults.canEditTitle = true
        }

        if (options.canEditURL == true) {
            defaults.canEditURL = true
        }
        
        return defaults
    }

    titlesForProp() {
        return {
            url: 'URL'
        }
    }

    decode(props) {
        const title = props.canEditTitle && props.title !== undefined ? props.title : RequestInformationButtonComponent.title

        return (            
            <Section padding={20} paddingVertical={20} paddingHorizontal={20} id="request-information-action-button" title="Action Button">
                <ActionButton id="request-info" componentTitle={title} title={title} url={props.url ?? RequestInformationButtonComponent.placeholderURL}  canEditText={props.canEditTitle != null ? props.canEditTitle : false} canEditURL={props.canEditURL != null ? props.canEditURL : false} />
            </Section>
        )
    }

    encode(model, props) {
        props['url'] = model.url

        if (props.canEditTitle == true)  {
            props['title'] = model.title
        }

        return props
    }

    icon() {
        return (
            <svg width="100" height="70" viewBox="0 0 100 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_25_1617)">
                    <path d="M100 0H0V70H100V0Z" fill="white" />
                    <path d="M89.6763 6.97676H10.4463C7.68487 6.97676 5.44629 9.21534 5.44629 11.9768V59.1308C5.44629 61.8922 7.68487 64.1308 10.4463 64.1308H89.6763C92.4377 64.1308 94.6763 61.8922 94.6763 59.1308V11.9768C94.6763 9.21534 92.4377 6.97676 89.6763 6.97676Z" fill="#EDEFFA" />
                    <path opacity="0.5" d="M28.457 40L31.6372 30.8403H33.8906L37.0771 40H35.0586L34.3794 37.7783H31.1484L30.4692 40H28.457ZM32.71 32.6685L31.5864 36.3438H33.9414L32.8242 32.6685H32.71ZM40.906 40.146C38.7224 40.146 37.4846 38.8193 37.4846 36.4961V36.4834C37.4846 34.1855 38.7161 32.8589 40.906 32.8589C42.7595 32.8589 43.8577 33.8682 44.0227 35.4297V35.4487H42.2961L42.2898 35.436C42.1692 34.7568 41.7058 34.3125 40.9123 34.3125C39.9158 34.3125 39.3699 35.0869 39.3699 36.4834V36.4961C39.3699 37.9116 39.9221 38.6924 40.9123 38.6924C41.6741 38.6924 42.1247 38.3306 42.2898 37.6323L42.3025 37.6069H44.0163L44.01 37.645C43.8196 39.1685 42.7278 40.146 40.906 40.146ZM48.1943 40.0254C46.4931 40.0254 45.8076 39.4731 45.8076 38.1147V34.4077H44.7285V33.0112H45.8076V31.3418H47.6802V33.0112H49.1211V34.4077H47.6802V37.6895C47.6802 38.3179 47.9468 38.5972 48.5752 38.5972C48.8164 38.5972 48.9433 38.5845 49.1211 38.5654V39.9429C48.8926 39.9873 48.5498 40.0254 48.1943 40.0254ZM51.3694 31.9385C50.7981 31.9385 50.322 31.4814 50.322 30.9102C50.322 30.3325 50.7981 29.8755 51.3694 29.8755C51.9406 29.8755 52.4104 30.3325 52.4104 30.9102C52.4104 31.4814 51.9406 31.9385 51.3694 31.9385ZM50.4363 40V33.0112H52.2898V40H50.4363ZM57.02 40.146C54.8681 40.146 53.5732 38.7812 53.5732 36.5088V36.4961C53.5732 34.2427 54.8872 32.8589 57.02 32.8589C59.1528 32.8589 60.4668 34.2363 60.4668 36.4961V36.5088C60.4668 38.7876 59.1718 40.146 57.02 40.146ZM57.02 38.6924C58.0039 38.6924 58.5688 37.8862 58.5688 36.5088V36.4961C58.5688 35.1313 57.9975 34.3125 57.02 34.3125C56.0361 34.3125 55.4712 35.1313 55.4712 36.4961V36.5088C55.4712 37.8862 56.0297 38.6924 57.02 38.6924ZM61.7375 40V33.0112H63.591V34.0903H63.7053C64.0227 33.335 64.7146 32.8589 65.7111 32.8589C67.2663 32.8589 68.1042 33.811 68.1042 35.4805V40H66.2507V35.8994C66.2507 34.9155 65.8444 34.395 64.9685 34.395C64.1115 34.395 63.591 35.0107 63.591 35.9565V40H61.7375Z" fill="#A3ABD5" />
                </g>
                <defs>
                    <clipPath id="clip0_25_1617">
                        <rect width="100" height="70" fill="white" />
                    </clipPath>
                </defs>
            </svg>

        )
    }
}


export class LearnMoreButtonComponent {
    static placeholderURL = null
    static title = "Explore More"

    constructor(url, defaultOptions) {
        this.type = 'actionLearnMore'
        this.defaultURL = url
        this.defaultOptions = defaultOptions ?? {}
    }

    defaultProps() {
        let options = this.defaultOptions
        let defaults = {
            url: this.defaultURL,
            title: options.title,
        }
        if (options.canEditTitle == true) {
            defaults.canEditTitle = true
        }
        if (options.canEditURL == true) {
            defaults.canEditURL = true
        }
        return defaults
    }

    titlesForProp() {
        return {
            url: 'URL'
        }
    }

    decode(props) {
        const title = props.canEditTitle && props.title ? props.title : LearnMoreButtonComponent.title
        return (            
            <Section padding={20} paddingVertical={20} paddingHorizontal={20} id="learn-more-action-button" title="Action Button">
                    <ActionButton id="learn-more" componentTitle={title} title={title} url={props.url ?? LearnMoreButtonComponent.placeholderURL}  canEditText={props.canEditTitle != null ? props.canEditTitle : false} canEditURL={props.canEditURL != null ? props.canEditURL : false} />
            </Section>
        )
    }

    encode(model, props) {
        props['url'] = model.url

        if (props.canEditTitle == true)  {
            props['title'] = model.title
        }

        return props
    }

    icon() {
        return (
            <svg width="100" height="70" viewBox="0 0 100 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g clip-path="url(#clip0_25_1617)">
                    <path d="M100 0H0V70H100V0Z" fill="white" />
                    <path d="M89.6763 6.97676H10.4463C7.68487 6.97676 5.44629 9.21534 5.44629 11.9768V59.1308C5.44629 61.8922 7.68487 64.1308 10.4463 64.1308H89.6763C92.4377 64.1308 94.6763 61.8922 94.6763 59.1308V11.9768C94.6763 9.21534 92.4377 6.97676 89.6763 6.97676Z" fill="#EDEFFA" />
                    <path opacity="0.5" d="M28.457 40L31.6372 30.8403H33.8906L37.0771 40H35.0586L34.3794 37.7783H31.1484L30.4692 40H28.457ZM32.71 32.6685L31.5864 36.3438H33.9414L32.8242 32.6685H32.71ZM40.906 40.146C38.7224 40.146 37.4846 38.8193 37.4846 36.4961V36.4834C37.4846 34.1855 38.7161 32.8589 40.906 32.8589C42.7595 32.8589 43.8577 33.8682 44.0227 35.4297V35.4487H42.2961L42.2898 35.436C42.1692 34.7568 41.7058 34.3125 40.9123 34.3125C39.9158 34.3125 39.3699 35.0869 39.3699 36.4834V36.4961C39.3699 37.9116 39.9221 38.6924 40.9123 38.6924C41.6741 38.6924 42.1247 38.3306 42.2898 37.6323L42.3025 37.6069H44.0163L44.01 37.645C43.8196 39.1685 42.7278 40.146 40.906 40.146ZM48.1943 40.0254C46.4931 40.0254 45.8076 39.4731 45.8076 38.1147V34.4077H44.7285V33.0112H45.8076V31.3418H47.6802V33.0112H49.1211V34.4077H47.6802V37.6895C47.6802 38.3179 47.9468 38.5972 48.5752 38.5972C48.8164 38.5972 48.9433 38.5845 49.1211 38.5654V39.9429C48.8926 39.9873 48.5498 40.0254 48.1943 40.0254ZM51.3694 31.9385C50.7981 31.9385 50.322 31.4814 50.322 30.9102C50.322 30.3325 50.7981 29.8755 51.3694 29.8755C51.9406 29.8755 52.4104 30.3325 52.4104 30.9102C52.4104 31.4814 51.9406 31.9385 51.3694 31.9385ZM50.4363 40V33.0112H52.2898V40H50.4363ZM57.02 40.146C54.8681 40.146 53.5732 38.7812 53.5732 36.5088V36.4961C53.5732 34.2427 54.8872 32.8589 57.02 32.8589C59.1528 32.8589 60.4668 34.2363 60.4668 36.4961V36.5088C60.4668 38.7876 59.1718 40.146 57.02 40.146ZM57.02 38.6924C58.0039 38.6924 58.5688 37.8862 58.5688 36.5088V36.4961C58.5688 35.1313 57.9975 34.3125 57.02 34.3125C56.0361 34.3125 55.4712 35.1313 55.4712 36.4961V36.5088C55.4712 37.8862 56.0297 38.6924 57.02 38.6924ZM61.7375 40V33.0112H63.591V34.0903H63.7053C64.0227 33.335 64.7146 32.8589 65.7111 32.8589C67.2663 32.8589 68.1042 33.811 68.1042 35.4805V40H66.2507V35.8994C66.2507 34.9155 65.8444 34.395 64.9685 34.395C64.1115 34.395 63.591 35.0107 63.591 35.9565V40H61.7375Z" fill="#A3ABD5" />
                </g>
                <defs>
                    <clipPath id="clip0_25_1617">
                        <rect width="100" height="70" fill="white" />
                    </clipPath>
                </defs>
            </svg>

        )
    }
}

