import React from 'react';
import { Section, Stack, Text, Image, Info } from '@yapstudios/yap-content-builder';

export class UniversityComponent {
    static infoMessage = null;
    static title = "University Profile";
    static location = "Someplace, Somewhere"
    static iconURL = ""

    constructor(title, subtitle, iconURL) {
        this.type = 'institution';
        this.defaultTitle = title;
        this.defaultSubtitle = subtitle;
        this.defaultIconURL = iconURL;
    }

    defaultProps() {
        return {
            title: this.defaultTitle,
            subtitle: this.defaultSubtitle,
            iconURL: this.defaultIconURL
        };
    }

    decode(props, { style, isPreview }) {

        // Support passing as src or asset object for backward compatibility
        var icon = props.iconURL;
        var iconSrc = icon ? (icon.src ?? icon) : null;

        const placeholderStyle = {
            backgroundColor: style.colors.placeholder,
            backgroundImage: isPreview ? 'url(' + style.images.placeholder + ')' : null,
            backgroundSize: '16px 16px',
            borderRadius: 8 + 'px',
            width: '40px',
            height: '40px',
        };

        const placeholderElement = <div style={placeholderStyle}></div>;

        let iconImage = <Image readonly={true} crop={icon ? icon.crop : null} placeholder={icon == null} cornerRadius={10} src={iconSrc} width={40} height={40} />;

        const iconStyle = {
            width: '40px',
            height: '40px',
            borderRadius: '10px',
            border: '1px solid #00000015',
        };


        const iconElement = <div style={iconStyle}>{iconImage}</div>;

        let content = (
            <Stack spacing={10} direction={"horizontal"} align={"center"} justify={"leading"} borderBottom="1px solid" borderColor="separator" padding={20} paddingVertical={20} paddingHorizontal={20}>
                {icon ? iconElement : placeholderElement}
                <Stack spacing={2} paddingBottom={2} paddingTop={2} align={"leading"}>
                    <Text readonly={true} style={"headline"} color={"primary"} align="left" content={props.title}></Text>
                    <Text readonly={true} style={"subhead"} color={"secondary"} content={props.subtitle}></Text>
                </Stack>
            </Stack>
        );

        let withInfo = (
            <Info title={UniversityComponent.title} message={UniversityComponent.infoMessage}>
                {content}
            </Info>
        );

        return (
            <Section padding={0} id="action-button" title="Action Button">
                {UniversityComponent.infoMessage ? withInfo : content}
            </Section>
        );
    }

    encode(model, props) {
        props['title'] = model.title;
        props['defaultSubtitle'] = model.subtitle;
        return props;
    }
}
