import React from 'react'
import {  YapContentPreview, YapHeaderButton as CBHeaderButton,  YapAssetImage as CBAssetImage, YapContentPreviewsModal as CBContentPreviewsModal, useYapPageState as useCBPageState, YapContentBuilderIconProviderContext as CBMessageBuilderIconProviderContext } from '@yapstudios/yap-content-builder'
import { components } from './CollegeboardComponentLibrary'
import { styles, fonts } from "./CBStyles.js"

/**
 * Presents a preview of a message
 * @param {page} Page data
 * @param {width} (Optional) width
 * @returns {React.ReactElement} The view
 */
const CBMessagePreview = (props) => {
    var page = props.page


    if (!page) {
        page = {
            sections: [
                {
                    'type': 'message-header',
                    'props': {
                        'title': props.title ?? "title",
                        'caption': props.caption ?? "caption",
                        'isPreview' : true
                    }
                },
                {
                    "type": "institution",
                    "props": {
                        "title":  props.institutionTitle ?? "institutionTitle",
                        "subtitle": props.institutionLocation ?? "institutionLocation",
                        "iconURL": props.institutionLogo
                    }
                },
                {
                    'type': 'paragraph',
                    'props': {
                        'title': props.body ?? 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.',
                        "style": "body",
                        "align": "left"
                    }
                },
            ]
        }
    }
    return <YapContentPreview components={components} width={props.width} page={page} styles={styles} fonts={fonts} {...props} />
}

export default CBMessagePreview