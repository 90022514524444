import { CBMessageBuilder, CBPage, CBMessageBuilderIconProviderContext, CBMessageValidations, CBContentPreviewsModal, CollegeMessageTemplate, UniversityComponent, useCBPageState, CBMessageFeedPreview, CBMessagePreview, LearnMoreButtonComponent, RequestInformationButtonComponent, MessageHeaderComponent, TextComponent } from 'cb-message-builder'
import { fileUploader, loadAssets, groupAssets, deleteAsset, updateLibraryAsset } from './AppData'

// Message validations check functions
// The applyValidation method on the template will return each component type in the template, 
// and can switch on the type to return the appropriate validations
let messageValidations = (componentType) => {
    switch (componentType) {
        case 'message-header':
            return [
                CBMessageValidations.fieldRequired('title'),
                CBMessageValidations.fieldRequired('coverImage', { propertyKey: 'src', description: 'An image is required to be selected' }),
                CBMessageValidations.maximumLengthWarning('title', { length: 40 }),
                CBMessageValidations.imageDimensionsWarning('coverImage', { minimumWidth: 2048, minimumHeight: 2048 }),

                // Image Validator
                CBMessageValidations.image('coverImage', {

                    // Optional. Defaults to field name
                    // title: 'Image Validation',

                    // Required. Message to present when validation fails
                    description: (asset) => { return `The file type should be png but is ${asset.src ? fileExtension(asset.src) : 'unknown'}` },

                    // Required. The validation
                    check: (asset) => {
                        return asset.src ? fileExtension(asset.src) == 'png' : false
                    },

                    // Optional. If provided will show when the image is provided and passes validation
                    // validDescription: 'The file type of png is correct',

                    // Optional. If provided will show when the image is null
                    // infoDescription: 'The file type is recommended to be png',

                    // Optional (defaults to warning) - 'error' or 'warning'
                    // type: 'warning',

                }),
            ]
        case 'paragraph':
            return [
                CBMessageValidations.fieldRequired('title'),
                CBMessageValidations.urlProtocolsRequired('title', { protocols: ['https', 'http'] }),
                CBMessageValidations.minimumLengthWarning('title', { length: 50 })
            ]
        case 'image':
            return [
                CBMessageValidations.fieldRequired('image', { propertyKey: 'src', description: 'An image is required to be selected' }),
                CBMessageValidations.fieldRequired('footnote'),
                CBMessageValidations.imageDimensionsWarning('image', { minimumWidth: 2048, minimumHeight: 2048 }),
            ]
        case 'action':
            return [
                CBMessageValidations.fieldRequired('title'),
                CBMessageValidations.fieldRequired('url'),
                CBMessageValidations.urlProtocolsRequired('url', { protocols: ['https', 'http'] }),
            ]
        case 'actionRequestInformation':
            return [
                CBMessageValidations.fieldRequired('title', { allowDefault: true, alwaysShowIsRequired: false, filter : (props) => { return props.canEditTitle == true }}),
            ]            
        default:
            break
    }
    return null
}


function fileExtension(url) {
    // Extract the pathname from the URL
    let pathname = new URL(url).pathname;
    
    // Split the pathname by '.' and get the last part
    let parts = pathname.split('.');
    return parts.length > 1 ? parts.pop() : null;
}

export { messageValidations }