import React from 'react';
import { Section, Stack, Text } from '@yapstudios/yap-content-builder';

export class TextComponent {
    static title = "Message Text";

    constructor(text, style) {
        this.type = 'paragraph';
        this.defaultText = text ?? "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam";
        this.defaultStyle = style ?? "body";
    }

    defaultProps() {
        return {
            title: this.defaultText,
            style: this.defaultStyle,
            align: "left"
        };
    }

    titlesForProp() {
        return {
            title: TextComponent.title
        };
    }


    decode(props) {
        return (
            <Section padding="0" paddingVertical="0" id="text" title="Header">
                <Stack padding={20} paddingBottom={0} spacing={20}>
                    <Text componentTitle={TextComponent.title} id="header" canEditStyle={false} content={props.title} style={props.style} align={props.align} color="primary" />
                </Stack>
            </Section>
        )
    }

    encode(model, props) {
        props['title'] = model.content;
        props['style'] = model.style;
        props['align'] = model.align;
        return props;
    }


    icon() {
        if (this.defaultStyle == 'h2') {
            return (
                <svg width="101" height="70" viewBox="0 0 101 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="100" height="70" transform="translate(0.319061)" fill="white"/>
                    <rect opacity="0.3" x="12.8257" y="15.3874" width="74.1245" height="16.77" rx="4" fill="#384CC0"/>
                    <rect opacity="0.3" x="12.8257" y="39.9683" width="42.2867" height="16.77" rx="4" fill="#384CC0"/>
                </svg>
            )
        } else {
            return (
                <svg width="100" height="70" viewBox="0 0 100 70" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect width="100" height="70" fill="white"/>
                    <rect opacity="0.3" x="8.6817" y="13.3874" width="82.6366" height="6" rx="3" fill="#384CC0"/>
                    <rect opacity="0.3" x="8.6817" y="25.3874" width="82.6366" height="6" rx="3" fill="#384CC0"/>
                    <rect opacity="0.3" x="8.6817" y="37.3874" width="64.8256" height="6" rx="3" fill="#384CC0"/>
                    <rect opacity="0.3" x="8.6817" y="49.3874" width="35.9871" height="6" rx="3" fill="#384CC0"/>
                </svg>          
            )
        }
    }
}
