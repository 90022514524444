import React from 'react'
import { YapContentValidations } from '@yapstudios/yap-content-builder'

class MessageTemplate {
    constructor(props) {

    }

    // Call the provided function for each component and apply the validation
    applyValidation(callback) {
        return this.components().flatMap(({ component }) => {
            YapContentValidations._currentComponent = component
            let result = callback(component.type)
            YapContentValidations._currentComponent = null
            return result
        }).filter(a => a)
    }

    components() {
        return []
    }
}

export { MessageTemplate }