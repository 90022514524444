import React from 'react';
import { Section, Stack, Text, Image, ZStack } from '@yapstudios/yap-content-builder';

export class MessageHeaderComponent {

    static dateFormatter = formatDate;
    static title = "Message Title";
    static imageTitle = "Header Image";
    static canEditImageAltText = true;

    constructor(title, date, imageSrc, imagePlaceholderMessage) {
        this.defaultTitle = title;
        this.imageSrc = imageSrc;
        this.imagePlaceholderMessage = imagePlaceholderMessage;
        this.type = 'message-header';
        this.defaultDate = date ?? new Date();
    }

    defaultProps() {
        return {
            title: this.defaultTitle ?? "New Message",
            caption: MessageHeaderComponent.dateFormatter(this.defaultDate),
            coverImage: { src: this.imageSrc, contentMode: "cover", placeholderMessage: this.imagePlaceholderMessage }
        };
    }


    titlesForProp() {
        return {
            title: MessageHeaderComponent.title,
            caption: 'Caption',
            coverImage: MessageHeaderComponent.imageTitle
        };
    }


    decode(props, { style }) {

        var title = props.title;
        var titleStyle = props.titleStyle ?? "titleHeader";
        var caption = props.caption;
        var image = props.coverImage ?? { src: null, contentMode: "cover" };

        // var emptyImage = <div style={{ "backgroundColor" : "#ECECEC", height: '350px', width: '100%' }}></div>
        var placeholderStyle = {};
        if (props.isPreview) {
            placeholderStyle['backgroundColor'] = style.colors.placeholder;
        }
        return (
            <Section padding="0" paddingVertical="0" id="section-header" title="Header">
                <Stack paddingTop={100} paddingHorizontal={20} paddingBottom={25} spacing={5}>
                    <Text id="title" componentTitle={MessageHeaderComponent.title} content={title} style={titleStyle} canEditStyle={false} align="left" color="primary" />
                    <Text id="caption" readonly={true} content={caption} style="captionUppercased" align="left" color="secondary" />
                </Stack>
                <ZStack height={350}>
                    <Image componentTitle={MessageHeaderComponent.imageTitle} canEditAltText={MessageHeaderComponent.canEditImageAltText} placeholder={true} placeholderStyle={placeholderStyle} placeholderMessage={this.imagePlaceholderMessage} src={image.src} description={image.description} height="350" contentMode={image.contentMode} id="coverImage" accessibilityLabel="Icon" {...image}></Image>
                    <Stack height={350} direction={"vertical"} align={"center"} justify={"center"} spacing={"spaceBetween"}>
                        {image.src == null && <Text id="placeholder" content={image.placeholderMessage} style={"body"} readonly={true} align="center" color="rgba(255,255,255,0.4)" />}
                    </Stack>
                </ZStack>
            </Section>
        );
    }

    encode(model, props) {
        switch (model['id']) {
            case 'title':
                props['title'] = model.content;
                props['titleStyle'] = model.style;
                break;
            case 'caption':
                props['caption'] = model.content;
                break;
            case 'coverImage':
                props['coverImage'] = model;
            default:
                break;
        }

        return props;
    }
}

export function formatDate(date) {
    const months     = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
    const monthsLong = ['JANUARY', 'FEBRUARY', 'MARCH', 'APRIL', 'MAY', 'JUNE', 'JULY', 'AUGUST', 'SEPTEMBER', 'OCTOBER', 'NOVEMBER', 'DECEMBER'];
    const days       = ['SUN', 'MON', 'TUE', 'WED', 'THU', 'FRI', 'SAT'];
    const daysLong   = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];

    const dayOfWeek  = days[date.getDay()];
    const dayOfMonth = date.getDate();
    const month      = months[date.getMonth()];
    const hours      = date.getHours() % 12 || 12;
    const minutes    = date.getMinutes().toString().padStart(2, '0');
    const ampm      = date.getHours() >= 12 ? 'PM' : 'AM';

    const showTime = false

    if (showTime) {
        return `${dayOfWeek} ${dayOfMonth} ${month}, ${hours}:${minutes} ${ampm}`;
    } else {
        return `${month} ${dayOfMonth}`;
    }
}
