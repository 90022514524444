
var emptyImageLight = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAQAAAAEACAYAAABccqhmAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAMnSURBVHgB7dYBEYAwEMCwBzHzb2czM/DRRESvz977DllrraHrHSBLACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACDsub8h65wzdDkACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACBMACPsAGA4MhUaQUtgAAAAASUVORK5CYII="
var emptyImageDark = "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAQAAAAEACAYAAABccqhmAAAACXBIWXMAABYlAAAWJQFJUiTwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAMqSURBVHgB7dZBEYAwEMDAg3e1VED9K6ghEJJdEZk855xvyLr3Dl3vAFkCAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGECAGHPWusbsvbeQ5cDgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgDABgLAfUQsHzwUPBscAAAAASUVORK5CYII="


const lightColors = {
    background: 'white',
    contentBackground: 'white',
    primary: '#000000',
    secondary: '#00000055',
    separator: '#00000022',
    placeholder: '#ECECEC'
}

const darkColors = {
    contentBackground : '#181818',
    background: '#000000',
    primary: '#ffffff',
    secondary: '#717171',
    separator: '#262626',
    placeholder: '#262626'
}

const lightImages = {
    placeholder: emptyImageLight
}

const darkImages = {
    placeholder: emptyImageDark
}

const styles = {
    'default': { name: 'Light', colors: lightColors, images: lightImages, userSelectable: false },
    //'dark': { name: 'Dark', colors: darkColors, images: darkImages , userSelectable: false },
}

var fonts = { 
    titleHeaderCollege: { name: 'Title Header', size: 34, weight: 'bold',  lineHeight: 1.2, color: "primary", fontStyle: 'regular', fontFamily: 'Roboto Slab, -apple-system-ui-serif, ui-serif' },
    titleHeaderCollegeSmall: { name: 'Title Header', size: 14, weight: 'bold',  lineHeight: 1.2, color: "primary", fontStyle: 'regular' },
    titleHeader : { name: 'Title Header', size: 34, weight: 'bold',  lineHeight: 1.2, color: "primary", fontStyle: 'regular' },
    h1 : { name: 'Heading 1', size: 28, weight: 'bold', color: "primary" },
    h2 : { name: 'Heading 2', size: 18,  weight: 'bold',color: "primary"},
    h3 : { name: 'Heading 3', size: 16, weight: 'bold', color: "primary" },
    body : { name: 'Body', size: 17, lineHeight: 1.5, color: "primary" },
    subtitle : { name: 'Subtitle', size: 14, color: "secondary" },
    headline : { name: 'Headline', size: 16, color: "secondary",weight: 'bold' },
    headline2 : { name: 'Headline 2', size: 16, color: "secondary",weight: 'bold' },
    quote : { name: 'Quote', size: 28, weight: 'bold', color: "primary", fontFamily: '-apple-system-ui-serif, ui-serif', fontStyle: 'italic' },
    subhead: { name: 'Subhead', size: 15, weight: 'regular', color: "primary"},
    caption: { name: 'Caption', size: 12, weight: 'regular', color: "secondary"},
    captionUppercased: { name: 'Caption (Uppercase)', size: 12, weight: 'bold', color: "secondary", textTransform: "uppercase"},
    footnote: { name: 'Footnote', size: 13, lineHeight: 1.5, weight: 'regular', color: "secondary" }
}

export { styles, fonts }
