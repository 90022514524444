import React from 'react'
import { YapContentPreview } from '@yapstudios/yap-content-builder'
import { Section, Stack, Text, Image, Box, ZStack } from '@yapstudios/yap-content-builder'
import { styles, fonts } from "./CBStyles.js"
import MessageCardComponent from './CBMessageCardComponent.js'

var avatarIcon = (
    <svg width="35" height="36" viewBox="0 0 35 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="17.5" cy="18.1313" r="17.5" fill="white" />
        <path fillRule="evenodd" clipRule="evenodd" d="M5.88574 31.2222C8.66353 27.0783 12.9116 24.4287 17.6725 24.4287C22.359 24.4287 26.5486 26.9961 29.328 31.029C26.2132 33.8869 22.0602 35.6313 17.4998 35.6313C13.0431 35.6313 8.97546 33.9654 5.88574 31.2222Z" fill="black" fillOpacity="0.14" />
        <path fillRule="evenodd" clipRule="evenodd" d="M7.23362 31.0495C10.0516 33.2922 13.6183 34.6313 17.4998 34.6313C21.4801 34.6313 25.1295 33.2231 27.9798 30.8764C25.3699 27.4879 21.6936 25.4287 17.6725 25.4287C13.5837 25.4287 9.8505 27.5583 7.23362 31.0495ZM29.328 31.029C26.5486 26.9961 22.359 24.4287 17.6725 24.4287C12.9116 24.4287 8.66353 27.0783 5.88574 31.2222C8.97546 33.9654 13.0431 35.6313 17.4998 35.6313C22.0602 35.6313 26.2132 33.8869 29.328 31.029Z" fill="#D4D4D4" />
        <path d="M22.7698 15.3572C22.7698 18.537 20.3775 21.0528 17.5001 21.0528C14.6227 21.0528 12.2305 18.537 12.2305 15.3572C12.2305 12.1775 14.6227 9.66162 17.5001 9.66162C20.3775 9.66162 22.7698 12.1775 22.7698 15.3572Z" fill="black" fillOpacity="0.14" stroke="#D4D4D4" />
        <circle cx="17.5" cy="18.1313" r="17" stroke="#D4D4D4" />
    </svg>
)


class LargeHeaderComponent {
    constructor(title, subtitle) {
        this.defaultTitle = title ?? "Colleges"
        this.type = 'large-header'
    }

    defaultProps() {
        return {
            title: null,
            subtitle: null
        }
    }

    decode(props) {
        var title = props.title ?? this.defaultTitle
        var divider = { height: "1px", borderTop: "1px solid #e3e3e3", marginTop: "8px", marginBottom: "8px" }

        return (
            <Section padding="0" paddingVertical="0" id="section-header" title="Header">
                <Stack paddingTop={100} paddingHorizontal={20} paddingBottom={0} spacing={5}>
                    <Stack direction="horizontal" spaceItems={"between"}>
                        <Text id="title" content={title} style={"titleHeader"} canEditStyle={false} align="left" color="primary" />
                        {avatarIcon}
                    </Stack>
                    <div style={divider} />
                </Stack>
            </Section>
        )
    }

    encode(model, props) {
        return props
    }
}

class SectionHeader {
    constructor() {
        this.type = 'section-header'
    }

    defaultProps() {
        return {}
    }

    decode(props) {
        return (
            <Section id="section-header" paddingHorizontal={20} paddingVertical={15} paddingBottom={15}>
                <Stack spacing={2} justify={"leading"} align={"leading"}>
                    <Text style={"h2"} content={props.title} />
                    {props.subtitle && <Text style={"body"} content={props.subtitle} color={"secondary"} />}
                </Stack>
            </Section>
        )
    }
}


class CollegeTiles {
    constructor() {
        this.type = 'college-tiles'
    }

    defaultProps() {
        return {}
    }

    decode(props, { style }) {
        const divider = { height: "1px", borderTop: "1px solid #e3e3e3", marginTop: "16px", marginBottom: "16px", marginLeft: '80px', marginRight: '20px' }

        let emptyLogo = <div style={{
            width: `36px`,
            height: `36px`,
            borderRadius: '8px',
            backgroundColor: style.colors.background
        }}></div>

        let emptyBackground = <div style={{
            width: `164px`,
            height: `127px`,
            position: 'absolute',
            left: '0px',
            borderRadius: '16px',
            backgroundColor: style.colors.placeholder
        }}></div>

        var placeholderStyle = {
            backgroundImage: 'url(' + style.images.placeholder + ')',
            backgroundSize: '16px 16px',
            backgroundRepeat: 'repeat'
        }


        let tiles = (props.colleges ?? []).map(college => {

            let prounounceLogo = college.placeholder && !college.background && !college.logo ? true : false

            var placeholderStyleImage = {
                ...placeholderStyle,
                border: '2.5px solid white',
                boxSizing: 'border-box',
                opacity: prounounceLogo ? 0.8 : 1.0
            }
            
            var shadowStyle = {
                boxShadow: '0px 0px 20px #00000050',
                borderRadius: '10px',
                position: 'relative'
            }
    
            let emptyBox = (
                    <svg width={36} style={shadowStyle} height={36} viewBox={`0 0 ${36} ${36}`} fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect width={36} height={36} rx={10} fill={"white"} />
                    </svg>
            )

            // Support passing as src or asset object for backward compatibility
            const logo = college.logo
            const logoSrc = logo ? (logo.src ?? logo) : null
            
            const background = college.background
            const backgroundSrc = background ? (background.src ?? background) : null

            return (

                <Stack spacing={10} direction={"vertical"} justify={"leading"} align={"leading"} spaceItems={"between"} height={180}>
                    <ZStack width={"164"} align={"trailing"} justify={"trailing"}>
                        {background || college.placeholder ? <Image placeholderStyle={placeholderStyle} placeholder={true} width={164} height={127} cornerRadius={16} description={college.backgroundDescription} crop={background ? background.crop : null} src={backgroundSrc} /> : emptyBackground}

                        <Stack direction={"horizontal"} align={"trailing"} justify={"leading"} height={127}>
                            <Box padding={10}>
                                <ZStack width={"36"} height={"36"}>
                                    {prounounceLogo && emptyBox}
                                    {logo || college.placeholder ? <Image placeholderStyle={placeholderStyleImage} description={college.logoDescription} placeholder={true} width={36} height={36} cornerRadius={10} crop={logo ? logo.crop : null} src={logoSrc} /> : emptyLogo}
                                </ZStack>
                            </Box>
                        </Stack>
                    </ZStack>

                    <Stack direction={"horizontal"} align={"leading"} justify={"leading"} height={44} width={167}>
                        <Box paddingRight={20}>
                            <Text style={"headline"} lineHeight={1.5} content={college.title} align="left" color={"primary"} />
                        </Box>
                    </Stack>
                </Stack>
            )
        })

        const container = { 'overflowX': 'scroll', 'overflowY': 'hidden', 'width': '375px', 'display': 'flex', paddingBottom: '4px' }


        return (
            <Section id="college-tiles" paddingHorizontal={0} paddingVertical={0} paddingBottom={25}>
                <div style={container}>
                    <Stack spacing={8} paddingHorizontal={20} direction={"horizontal"}>

                        {tiles}

                    </Stack>
                </div>
            </Section>
        )
    }
}

class CollegeRow {
    constructor() {
        this.type = 'college-row'
    }

    defaultProps() {
        return {}
    }

    decode(props) {
        const divider = { height: "1px", borderTop: "1px solid #e3e3e3", marginTop: "16px", marginBottom: "16px", marginLeft: '90px', marginRight: '20px' }

        let emptyLogo = <div style={{
            width: `68px`,
            height: `68px`,
            borderRadius: '16px',
            backgroundColor: 'rgba(0,0,0,0.2)'
        }}></div>


        return (
            <Section id="college-row" paddingHorizontal={20} paddingVertical={0} paddingBottom={0}>
                <Stack spacing={20} direction={"horizontal"} justify={"leading"} align={"center"}>
                    {props.logo ? <Image width={68} height={68} cornerRadius={16} src={props.logo} /> : emptyLogo}

                    <Stack direction={"vertical"} align={"leading"} justify={"center"}>
                        <Text style={"headline"} textAlign={"left"} content={props.title} color={"primary"} />
                        <Text style={"body"} textAlign={"left"} content={props.subtitle} color={"secondary"} />
                    </Stack>
                </Stack>

                <div style={divider}></div>

            </Section>
        )
    }
}

const collegeListComponents = [
    new MessageCardComponent(),
    new LargeHeaderComponent(),
    new SectionHeader(),
    new CollegeRow(),
    new CollegeTiles()
]


/*
* Presents a preview of an college list page
* @param {page} Page data
* @param {width} (Optional) width
* @returns {React.ReactElement} The view
*/
const CBCollegeListPreview = (props) => {

    let tileSection = [
        {
            'id': 'section-header',
            'type': 'section-header',
            'props': { title: props.tilesHeader ?? 'Interested in You', subtitle: props.tilesHeaderSubheader ?? 'Colleges that have contacted you' }
        },

        {
            'id': 'college-tiles',
            'type': 'college-tiles',
            'props': {
                colleges: props.tileItems
            }
        },

    ]

    let listRows = (props.listItems ?? []).map(row => {
        return {
            'id': 'college-row',
            'type': row.type ?? 'college-row',
            'props': {
                title: row.title,
                subtitle: row.subtitle,
                logo: row.logo
            }
        }
    })


    let listSection = [{
        'id': 'section-header-your-list',
        'type': 'section-header',
        'props': { title: props.listHeader ?? 'Your List' }
    }].concat(listRows)


    let sections = [
        {
            'id': 'large-header',
            'type': 'large-header',
            'props': { title: props.title ?? "Colleges" }
        },

    ]

    if (props.tileItems) {
        sections = sections.concat(tileSection)
    }

    if (props.listItems) {
        sections = sections.concat(listSection)
    }

    let page = {
        id: 'page-1',
        sections: sections
    }

    return <YapContentPreview components={collegeListComponents} styles={styles} fonts={fonts} width={props.width} {...props} page={page} />
}

/*
* Presents a preview of an college list page
* @param {page} Page data
* @param {width} (Optional) width
* @returns {React.ReactElement} The view
*/
const CBSavedListPreview = (props) => {

    let tileSection = [
        {
            'id': 'section-header',
            'type': 'section-header',
            'props': { title: props.tilesHeader ?? 'Colleges', subtitle: props.tilesHeaderSubheader ?? "" }
        },

        {
            'id': 'college-tiles',
            'type': 'college-tiles',
            'props': {
                colleges: (props.tileItems ?? []).map( (tile, idx) => {
                    return { ...tile, placeholder: idx == 0 }
                })
            }
        },

    ]

    let listRows = (props.listItems ?? []).map( (row, idx) => {
        return {
            'id': 'message-card',
            'type': row.type ?? 'message-card',
            'props': {
                ...row,
                title: row.title ?? '',
                subtitle: row.subtitle ?? '',
                logo: row.logo ?? ''
            }
        }
    })


    let listSection = [{
        'id': 'section-header-your-list',
        'type': 'section-header',
        'props': { title: props.listHeader ?? 'Messages' }
    }].concat(listRows)


    let sections = [
        {
            'id': 'large-header',
            'type': 'large-header',
            'props': { title: props.title ?? "Saved" }
        },

    ]

    if (props.tileItems) {
        sections = sections.concat(tileSection)
    }

    if (props.listItems) {
        sections = sections.concat(listSection)
    }

    let page = {
        id: 'page-1',
        sections: sections
    }

    return <YapContentPreview components={collegeListComponents} width={props.width} styles={styles} fonts={fonts} {...props} page={page} />
}

export { CBCollegeListPreview, CBSavedListPreview }