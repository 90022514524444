import {  useCBPageState } from 'cb-message-builder'
import { CBHeaderButton } from 'cb-message-builder'
import { packageInformation } from 'cb-message-builder'
import { VStack, Text } from '@yapstudios/yap-content-builder'
/**
 * Example of a custom header that triggers the done action from a custom button.
 * 
 * This example is for when need complete control over what actions are shown & the style of them
 */
export function CustomHeader(props) {
    const { title, previewAction } = props

    // Get the done action that is set for a CBPage
    //
    // You could choose to send the done action to something up further in your hierarchy
    // or return a header here with actions as to where its used 
    let { doneAction, containerClassName }  = useCBPageState()

    let borderStyle = {
        borderBottom: '1px solid #e3e3e3',
        position: 'relative',
        zIndex: 1000
    }

    let headerStyle = {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        alignContent: 'flex-end',
        minHeight: '80px',
        margin: '0 auto 0 auto',
        padding: '0px 50px 0px 50px',
        background : 'white'
    }

    let buttonContainerStyle = {
        display: 'flex',
        gap: 44
    }

    let subtitleStyle = {
        display: 'flex',
        alignItems: 'flex-start',
        alignContent: 'flex-start',
        gap: '10px',
        color: '#aaaaaa'
    }

    let iconIcon = (
        <svg width="15" height="9" viewBox="0 1 15 9" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M7.58398 8.92334C7.23242 8.92334 6.91016 8.7793 6.61719 8.49121L1.19727 2.94678C1.08984 2.83447 1.00684 2.7124 0.948242 2.58057C0.894531 2.44385 0.867676 2.29736 0.867676 2.14111C0.867676 1.92139 0.918945 1.72363 1.02148 1.54785C1.12891 1.36719 1.27051 1.22559 1.44629 1.12305C1.62207 1.01562 1.81738 0.961914 2.03223 0.961914C2.35938 0.961914 2.64746 1.08643 2.89648 1.33545L7.88428 6.46973H7.29102L12.2642 1.33545C12.5132 1.08643 12.7988 0.961914 13.1211 0.961914C13.3359 0.961914 13.5312 1.01562 13.707 1.12305C13.8828 1.22559 14.022 1.36719 14.1245 1.54785C14.2319 1.72363 14.2856 1.92139 14.2856 2.14111C14.2856 2.4585 14.1758 2.72705 13.9561 2.94678L8.54346 8.49121C8.39697 8.6377 8.24561 8.74512 8.08936 8.81348C7.93311 8.88184 7.76465 8.91846 7.58398 8.92334Z" fill="#384CC0"/>
</svg>

    )

    return (
        <div style={borderStyle}>
            <div classNaeme={containerClassName} style={headerStyle}>
                <div>
                    <h2 style={{"margin" : "0px", "padding-bottom" : "4px"}}>{title}</h2>
                        <span style={subtitleStyle}>
                            {packageInformation.name} {packageInformation.version}                    
                            <CBHeaderButton content={<VersionInfo {...props}/>}>{iconIcon}</CBHeaderButton>
                    </span>
                </div>
                <div style={buttonContainerStyle}>
                    <CBHeaderButton onClick={previewAction}>{"Preview"}</CBHeaderButton>
                    <CBHeaderButton content={<MenuContentNew {...props}/>}>{"New"}</CBHeaderButton>
                    <CBHeaderButton content={<MenuContentLoad {...props}/>}>{"Load"}</CBHeaderButton>
                    <CBHeaderButton content={<MenuContentOptions {...props}/>}>{"Options"}</CBHeaderButton>
                    <CBHeaderButton primary={true} onClick={doneAction}>{"Save"}</CBHeaderButton>
                </div>
            </div>
        </div>
    )
}

function VersionInfo() {
    var style = {
        color: '#000000'
    }
    var header = {
        margin: '0px'
    }
    var paragraph = {
        margin: '0px'
    }
    return (
        <div style={style}>
        <VStack align="leading" spacing="20" padding="10">
            <VStack align="leading" spacing="5" padding="0">
                <h4 style={header}>{"cb-message-builder"}</h4>
                <p style={paragraph}>{packageInformation.version}</p>
                <a href="http://github.com/yapstudios/cb-message-builder">{"github"}</a>
            </VStack>
            <VStack align="leading" spacing="5" padding="0">
                <h4 style={header}>{"yap-content-builder"}</h4>
                <p style={paragraph}>{packageInformation.yapContentBuilder.version}</p>
            </VStack>
        </VStack>
        </div>
    )
}

function MenuContentNew({ newPageAction, newEditablePageAction }) {
    return (
        <VStack spacing={10} padding={0}>
            <MenuLargeButton onClick={newPageAction}>{"College Message"}</MenuLargeButton>
            <MenuLargeButton onClick={() => { newPageAction({ editableActionTitle: true })}} description={"With editable action title"}>{"College Message"}</MenuLargeButton>
            <MenuLargeButton onClick={newEditablePageAction} description={"Message with editable components and selectable templates"}>{"Editable Message"}</MenuLargeButton>
        </VStack>
    )
}

function MenuContentLoad({ loadPageAction }) {
    return (
        <VStack spacing={10} padding={0}>
            <MenuLargeButton onClick={() => { loadPageAction(0) } }>{"Basic Message"}</MenuLargeButton>
            <MenuLargeButton onClick={() => { loadPageAction(1) } }>{"Message with Image"}</MenuLargeButton>
        </VStack>
    )
}

function MenuContentOptions({ setOptions, options }) {
    const toggle = (optionName) => {
        var newOptions = {...options}
        newOptions[optionName] = !newOptions[optionName]
        setOptions(newOptions)
    }

    return (
        <VStack spacing={10} padding={0}>
            <MenuLargeButton value={options.cropping} onClick={() => { toggle('cropping') } }>{"Cropping"}</MenuLargeButton>
            <MenuLargeButton value={options.multiUpload} onClick={() => { toggle('multiUpload') } }>{"Multi Upload"}</MenuLargeButton>
            <MenuLargeButton value={options.assetFolders} onClick={() => { toggle('assetFolders') } }>{"Asset Folders"}</MenuLargeButton>
            <MenuLargeButton value={options.debug} onClick={() => { toggle('debug') } }>{"Debug"}</MenuLargeButton>
        </VStack>
    )
}

function MenuLargeButton({ children , value, description, onClick }) {

    var title = <span className="title">{children}</span>
    var titleContent = title

    if (value != null) {
        titleContent = (
            <div className="title-value">
                {title}
                <input type={"checkbox"} checked={value}/>
            </div>
        )
    }
    return (
        <div className="large-menu-button" onClick={onClick}>
            {titleContent}
            {description && <span className="description" >{description}</span>}
        </div>
    )
}
