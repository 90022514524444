import React from 'react'
import { MessageTemplate } from "./MessageTemplate.js"
import { MessageHeaderComponent, TextComponent, ImageComponent, QuizComponent, ActionButtonComponent, LearnMoreButtonComponent, RequestInformationButtonComponent, UniversityComponent } from '../CollegeboardComponentLibrary.js'

/**
 * Default College Mesasge Template
 */
export class CollegeMessageTemplate extends MessageTemplate {
    constructor(props) {
        super(props)

        this.props = props ?? { editable: false }
        this.isEditable = this.props == null || this.props.editable == null ? false : this.props.editable

        var action = 'action' in props ? props.action : 'custom'

        switch (action) {
            case 'learnMore':
            case 'deeplink':
                this.actionButton = {
                    canDelete: this.isEditable,
                    canMove: this.isEditable,
                    component: new LearnMoreButtonComponent(props.actionURL, { title: props.actionTitle, canEditTitle: props.actionCanEditTitle, canEditURL: props.actionCanEditURL })
                };
                break;
            case 'requestInformation':
            case 'external':                
                this.actionButton = {
                    canDelete: this.isEditable,
                    canMove: this.isEditable,
                    component: new RequestInformationButtonComponent(props.actionURL, { title: props.actionTitle, canEditTitle: props.actionCanEditTitle, canEditURL: props.actionCanEditURL })
                };
                break;
            case 'custom':
            case 'userAction':
            case 'internal':                                
                this.actionButton = {
                    canDelete: this.isEditable,
                    canMove: this.isEditable,
                    component: new ActionButtonComponent(props.actionURL, { title: props.actionTitle, canEditTitle: props.actionCanEditTitle, canEditURL: props.actionCanEditURL })
                };
                break;
            default:
                this.actionButton = null;
                break;
        }
    }

    components() {
        var institution = this.props.institution ?? { name: "University", location: "Location" }
        var headerImage = this.props.headerImage ?? { src: null, footnote: null, placeholderMessage: null, fullBleed: false }

        return [
            { canDelete: false, canMove: false, component: new MessageHeaderComponent(this.props.title, this.props.date, headerImage.src, headerImage.placeholderMessage ?? "abcd") },
            { canDelete: false, canMove: false, component: new UniversityComponent(institution.name, institution.location, institution.iconURL) },
            { canDelete: this.isEditable, canMove: this.isEditable, component: new TextComponent(this.props.body ?? "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam") },
            this.actionButton
        ].filter(x => x); // Remove null (if action isnt defined)
    }
}