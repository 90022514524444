import React from 'react'
import { YapContentPreview } from '@yapstudios/yap-content-builder'
import { Section, Stack, Text } from '@yapstudios/yap-content-builder'
import './styles.css'
import { styles, fonts } from "./CBStyles.js"
import MessageCardComponent from './CBMessageCardComponent.js'

var avatarIcon = (
    <svg width="35" height="36" viewBox="0 0 35 36" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="17.5" cy="18.1313" r="17.5" fill="white" />
        <path fillRule="evenodd" clipRule="evenodd" d="M5.88574 31.2222C8.66353 27.0783 12.9116 24.4287 17.6725 24.4287C22.359 24.4287 26.5486 26.9961 29.328 31.029C26.2132 33.8869 22.0602 35.6313 17.4998 35.6313C13.0431 35.6313 8.97546 33.9654 5.88574 31.2222Z" fill="black" fillOpacity="0.14" />
        <path fillRule="evenodd" clipRule="evenodd" d="M7.23362 31.0495C10.0516 33.2922 13.6183 34.6313 17.4998 34.6313C21.4801 34.6313 25.1295 33.2231 27.9798 30.8764C25.3699 27.4879 21.6936 25.4287 17.6725 25.4287C13.5837 25.4287 9.8505 27.5583 7.23362 31.0495ZM29.328 31.029C26.5486 26.9961 22.359 24.4287 17.6725 24.4287C12.9116 24.4287 8.66353 27.0783 5.88574 31.2222C8.97546 33.9654 13.0431 35.6313 17.4998 35.6313C22.0602 35.6313 26.2132 33.8869 29.328 31.029Z" fill="#D4D4D4" />
        <path d="M22.7698 15.3572C22.7698 18.537 20.3775 21.0528 17.5001 21.0528C14.6227 21.0528 12.2305 18.537 12.2305 15.3572C12.2305 12.1775 14.6227 9.66162 17.5001 9.66162C20.3775 9.66162 22.7698 12.1775 22.7698 15.3572Z" fill="black" fillOpacity="0.14" stroke="#D4D4D4" />
        <circle cx="17.5" cy="18.1313" r="17" stroke="#D4D4D4" />
    </svg>
)

class FeedHeaderComponent {
    constructor(title, subtitle) {
        this.defaultTitle = title ?? "Messages"
        this.defaultSubtitle = subtitle
        this.type = 'feed-header'
    }

    defaultProps() {
        return {
            title: null,
            subtitle: null
        }
    }

    decode(props) {
        var title = props.title ?? this.defaultTitle
        var subtitle = props.subtitle

        var divider = { height: "1px", borderTop: "1px solid #e3e3e3", marginTop: "8px", marginBottom: "8px" }

        var font = { size: 24, lineHeight: 1.35, weight: '600', color: "primary" }

        const headerBackground = {
            background: 'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.1) 100%)',
            height: '390px',
            width: '100%',
            position: 'absolute',
            zIndex: 0,
            left: 0,
            top: 0
        }

        var subtitleElements = [
            <div key={"divider"} style={divider} />,
            <Section key={"section"} paddingRight={100}>
                <Text id="caption" readonly={true} content={subtitle} style={font} align="left" color="primary" />
            </Section>
        ]

        return (
            <Section padding="0" paddingVertical="0" id="section-header" title="Header">
                <Stack paddingTop={100} paddingHorizontal={20} paddingBottom={20} spacing={5}>
                    {subtitle && <div style={headerBackground} />}
                    <Stack direction="horizontal" spaceItems={"between"}>
                        <Text id="title" content={title} style={"titleHeader"} canEditStyle={false} align="left" color="primary" />
                        {avatarIcon}
                    </Stack>
                    {subtitle && subtitleElements}
                </Stack>
            </Section>
        )
    }

    encode(model, props) {
        return props
    }
}

class MessageListSpacerComponent {
    constructor(title, subtitle) {
        this.type = 'message-list-spacer'
    }


    defaultProps() {
        return {}
    }


    decode(props) {
        return (
            <Section paddingTop="5">&nbsp;</Section>
        )
    }
}

const feedComponents = [
    new FeedHeaderComponent(),
    new MessageCardComponent(),
    new MessageListSpacerComponent()
]


/*
* Presents a preview of a message in a feed
* @param {page} Page data
* @param {width} (Optional) width
* @returns {React.ReactElement} The view
*/
const CBMessageFeedPreview = (props) => {

    let image = props.image
    let message = props.message
    let headerMessage = props.headerMessage

    let extraMessages = (props.placeholderMessages ?? props.messages ?? []).flatMap( (message, idx) => {
        return [
            {
                'id': 'message',
                'type': 'message-card',
                'props': { ...message,
                           'logo': message.logo ?? props.logo, 
                           'logoAvatar' : message.logoAvatar ?? props.logoAvatar,
                           'institutionTitle' : message.institutionTitle ?? props.institutionTitle,
                           'logoType' : message.logoType,
                           'image': message.image, 
                           'placeholder': idx == 0,
                           'title': message.title }
            },
            // {
            //     'id': 'spacer',
            //     'type': 'message-list-spacer',
            //     'props': {}
            // },
        ]
    })

    let sections = [
        {
            'id': 'feed-header',
            'type': 'feed-header',
            'props': { 'subtitle': headerMessage }
        }
    ]

    let page = {
        id: 'page-1',
        sections: sections.concat(extraMessages)
    }
    return <YapContentPreview styles={styles} fonts={fonts} dark={props.dark} components={feedComponents} width={props.width} {...props} page={page} />
}

export default CBMessageFeedPreview