import React from 'react'
import { MessageHeaderComponent, TextComponent, ImageComponent, QuizComponent, ActionButtonComponent, LearnMoreButtonComponent, RequestInformationButtonComponent, UniversityComponent } from './CollegeboardComponentLibrary'
import { YapContentValidations } from '@yapstudios/yap-content-builder'
import { CollegeMessageTemplate } from './Templates/CollegeMessageTemplate.js'
import { BlankMessageTemplate } from './Templates/BlankMessageTemplate.js'
import { ImageMessageTemplate } from './Templates/ImageMessageTemplate.js'
import { QuizMessageTemplate } from './Templates/QuizMessageTemplate.js'
const templateLibrary = [
  {
    name: 'Basic',
    templates: [
      { template: new BlankMessageTemplate(), title: "Blank Message" },
      { template: new ImageMessageTemplate(), title: "Image" },
    ]
  },
]


export { templateLibrary, BlankMessageTemplate, ImageMessageTemplate, CollegeMessageTemplate, QuizMessageTemplate }